import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Ticker from '../../UiComponents/Ticker/Ticker';

import Arrow from '../../../svg/arrow-top-right.svg';

const BeautyDirectors = ({ items, tickers }) => {
  return (
    <div
      className="directors-portfolio second-section"
      id="second-section"
      data-scroll-section
    >
      <Ticker tickers={tickers} />
      <div className="content-wrapper">
        <div className="directors-portfolio__items">
          {items.Directors.map((item, index) => {
            const count = ++index;
            return (
              <div className="directors-portfolio__item" key={index}>
                <div className="directors-portfolio__item-content">
                  <span className="directors-portfolio__item-content__counter">
                    {count <= 9 ? '0' + count + ' /' : count + ' /'}
                  </span>
                  <div className="directors-portfolio__item-content__info">
                    <div className="directors-portfolio__item-content__info-wrapper">
                      <span className="directors-portfolio__item-content__info-wrapper__description">
                        Director:
                      </span>
                      <h2 className="directors-portfolio__item-content__info-wrapper__title">
                        {item.title}
                      </h2>
                    </div>
                    <Link
                      to={item.link}
                      className="directors-portfolio__item-content__info-wrapper__link"
                    >
                      <span className="info-wrapper__link-title">
                        View more
                      </span>
                      <Arrow className="info-wrapper__link-icon" />
                    </Link>
                  </div>
                </div>
                <div className="directors-portfolio__item-cover">
                  <div
                    className="directors-portfolio__item-cover__owerflow"
                    data-scroll=""
                    data-scroll-speed="-1.5"
                  >
                    <video
                      src={`https://admin.zerotabletop.com${item.video?.url}`}
                      playsInline={true}
                      webkit-playsinline=""
                      autoPlay={true}
                      muted={true}
                      loop={true}
                      className="directors-portfolio__item-cover__video"
                    ></video>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

BeautyDirectors.propTypes = {
  items: PropTypes.object,
  tickers: PropTypes.array,
};

export default BeautyDirectors;
