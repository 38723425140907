import React from 'react';
import PropTypes from 'prop-types';

import EllipseFill from '../../../svg/ellipse_fill.svg';
import EllipseSrtoke from '../../../svg/ellipse_stroke.svg';

const Ticker = ({ tickers }) => {
  return (
    <div className="ticker">
      <div className="ticker-wrapper">
        <div
          className="ticker-line ticker-line__top"
          data-scroll=""
          data-scroll-direction="horizontal"
          data-scroll-speed="3"
        >
          {tickers.map((item, index) => {
            return (
              <div className="ticker-line__item" key={index}>
                <span className="ticker-line__title ticker-line__title-top">
                  {item}
                </span>
                <span className="ticker-line__ellipse-wrapper">
                  <EllipseFill className="ticker-line__ellipse ticker-line__ellipse-top" />
                </span>
              </div>
            );
          })}
        </div>

        <div
          className="ticker-line ticker-line__middle"
          data-scroll=""
          data-scroll-direction="horizontal"
          data-scroll-speed="2.9"
        >
          {tickers.map((item, index) => {
            return (
              <div className="ticker-line__item" key={index}>
                <span className="ticker-line__title ticker-line__title-middle">
                  {item}
                </span>
                <span className="ticker-line__ellipse-wrapper">
                  <EllipseSrtoke className="ticker-line__ellipse ticker-line__ellipse-middle" />
                </span>
              </div>
            );
          })}
        </div>

        <div
          className="ticker-line ticker-line__bottom"
          data-scroll=""
          data-scroll-direction="horizontal"
          data-scroll-speed="2.8"
        >
          {tickers.map((item, index) => {
            return (
              <div className="ticker-line__item" key={index}>
                <span className="ticker-line__title ticker-line__title-bottom">
                  {item}
                </span>
                <span className="ticker-line__ellipse-wrapper">
                  <EllipseSrtoke className="ticker-line__ellipse ticker-line__ellipse-bottom" />
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Ticker.propTypes = {
  tickers: PropTypes.array,
};

export default Ticker;
