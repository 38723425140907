export const contactsData = {
  title: 'contacts \nzerotabletop',
  contacts_list: [
    {
      id: 1,
      name: 'Paolo Gandola',
      position: 'Creative Managing Director',
      mail: 'paolo.gandola@yahoo.it',
      phone: '+39 335 6644213',
    },
    {
      id: 2,
      name: 'Chiara Smiz',
      position: 'Ass. Producer',
      mail: 'chiarasmiz@gmail.com',
      phone: '+39 338 6600909',
    },
    {
      id: 3,
      name: 'Pola Brown',
      position: 'US EP / Superseed Content',
      mail: 'pola@superseedcontent.com',
      phone: '+1 323 528 2755',
    },
    {
      id: 4,
      name: 'Studios',
      address: 'VIA TERTULLIANO, 70 MILANO',
      map_link: 'https://goo.gl/maps/AiVnK3rnTHiU3Cjx9',
    },
    {
      id: 5,
      name: 'Offices',
      address: 'VIA PANFILO CASTALDI, 4 MILANO',
      map_link: 'https://goo.gl/maps/4Ts2SHbjEwCUe4qW7',
    },
  ],
  socials: [
    {
      id: 1,
      title: 'Instagram',
      link: 'https://www.instagram.com/zerotabletop/',
    },
    { id: 2, title: 'Vimeo', link: 'https://vimeo.com/user15150659' },
    {
      id: 3,
      title: 'Facebook',
      link: 'https://www.facebook.com/zerotabletop/',
    },
  ],
};
