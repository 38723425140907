import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/layout';
import Seo from '../components/seo';

import { MainScreenFirst, CrumbsNav } from '../components/Pages/About';

import { contactsData } from '../db/contactsData';
import { foodDirectorsStatic } from '../db/foodDirectorsStatic';
import BeautyDirectors from '../components/Pages/BeautyDirectors/BeautyDirectors';

const foodDirectorsPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title={data.strapiFoodDirectorsPage.seo_title} 
        description={data.strapiFoodDirectorsPage.seo_description}
      />
      
      <MainScreenFirst
        title={data.strapiFoodDirectorsPage.main.title}
        socials={contactsData.socials}
        showreel={data.strapiFoodDirectorsPage.main.showreel}
        video={data.strapiFoodDirectorsPage.main.video_cover}
        poster={data.strapiFoodDirectorsPage.main.poster}
      />

      <BeautyDirectors
        items={data.strapiFoodDirectorsPage}
        tickers={foodDirectorsStatic.tickers}
      />

      <CrumbsNav dataCrumbs={foodDirectorsStatic.crumbsNav} />
    </Layout>
  );
};

export default foodDirectorsPage;


export const query = graphql`
 query {
    strapiFoodDirectorsPage {
      seo_title
      seo_description
      main {
        title
        showreel
        video_cover {
        url
      }
      poster {
        url
      }
      }
      Directors {
        link
        title
        video {
          url
        }
      }
    }
  }
`